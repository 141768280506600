import Axios from 'axios';
import humps from 'humps';
import queryString from 'query-string';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

const ClientFactory = authToken => {
  const client = Axios.create({
    baseURL: `${publicRuntimeConfig.baseUrl}/api/`,
    withCredentials: true,
    headers: {
      ...(authToken ? { Authorization: `Bearer ${authToken}` } : {}),
      Accept: `application/json`,
    },
    paramsSerializer: params => {
      const data = humps.decamelizeKeys(params);

      return queryString.stringify(data, { arrayFormat: 'bracket' });
    },
    transformResponse: [
      ...Axios.defaults.transformResponse,
      data => humps.camelizeKeys(data),
    ],
    transformRequest: [
      data => humps.decamelizeKeys(data),
      ...Axios.defaults.transformRequest,
    ],
  });

  return client;
};

export default ClientFactory;
