import {
  createContext,
  useReducer,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { i18n as I18n } from 'utils/i18n';

import ClientFactory from '../../utils/apiClient';

import reducer, { initialState, actions } from './reducer';

import { isCustomer } from '../../utils/roles';

export const AuthContext = createContext({
  ...initialState,
  loggedIn: false,
  accountApproved: false,
  token: undefined,
  user: null,
  currentClient: null,
  role: 'Booker',
  clientServiceLevel: null,
  bookableServices: [],
  actions: {
    signIn: async () => {},
    signOut: async () => {},
    resendEmailConfirmationLink: async () => {},
    setReturnUrl: () => {},
    resetPassword: async () => {},
    setToken: () => {},
    refreshAuthToken: () => Promise.reject(new Error('Not logged in')),
  },
});

const emptyList = [];

const AuthProvider = ({ token, children }) => {
  const [
    { returnUrl, errorMessage, user, token: authToken, refreshingToken },
    dispatch,
  ] = useReducer(reducer, initialState);
  const router = useRouter();

  const client = useMemo(() => ClientFactory(authToken), [authToken]);

  const signOut = useCallback(async () => {
    dispatch({ type: actions.SIGN_OUT });

    const { status } = await ClientFactory(authToken).get(
      `/v1/users/auth/logout`
    );

    if (status === 204) {
      dispatch({
        type: actions.SIGN_OUT_SUCCESS,
      });

      router.push('/');
    }
  }, [authToken, router]);

  useEffect(() => {
    if (user && user.role && !isCustomer(user.role.displayName)) {
      signOut();
    }
  }, [signOut, user]);

  const setToken = useCallback(
    async token => {
      dispatch({ type: actions.SET_TOKEN, payload: token });

      const { data } = await ClientFactory(token).get(`/v1/users/me`);
      dispatch({
        type: actions.SET_USER,
        payload: data,
      });
    },
    [dispatch]
  );

  useEffect(() => {
    const refreshToken = async () => {
      if (token) {
        await setToken(token);
      }
    };

    refreshToken();
  }, [setToken, token]);

  const signIn = useCallback(
    async (email, password) => {
      dispatch({ type: actions.SIGN_IN });

      const pathNameFor = returnUrl => {
        const pathPrefixes = [
          '/register',
          '/change-password',
          '/confirm-email',
        ];

        const isDefaultPath =
          !returnUrl ||
          pathPrefixes.some(prefix => returnUrl.startsWith(prefix));

        const url = isDefaultPath ? '/' : decodeURIComponent(returnUrl);

        const [pathname, hashQuery] = url.split('#');
        const [hash, query] = (hashQuery || '').split('?');

        return { pathname, hash: hash || '', query: query || '' };
      };

      try {
        const { data } = await client.post(`/v1/users/auth/login`, {
          email,
          password,
          scope: 'client',
        });

        if (data.token) {
          setToken(data.token);

          dispatch({
            type: actions.SIGN_IN_SUCCESS,
          });

          router.push(pathNameFor(returnUrl));
        }

        return null;
      } catch (e) {
        return dispatch({
          type: actions.SIGN_IN_ERROR,
          payload: I18n.t('sign_in.validations.bad_credentials'),
        });
      }
    },
    [setToken, router, returnUrl, client]
  );

  const refreshAuthToken = useCallback(async () => {
    dispatch({ type: actions.REFRESH_TOKEN });

    try {
      const { data } = await client.post(`/v1/users/auth/refresh_token`);

      dispatch({ type: actions.SET_TOKEN, payload: data.token });

      client.interceptors.request.use(config => {
        return {
          ...config,
          headers: {
            ...config.headers,
            Authorization: `Bearer ${data.token}`,
          },
        };
      });

      dispatch({ type: actions.REFRESH_TOKEN_SUCCESS });

      const { data: user } = await client.get(`/v1/users/me`);

      dispatch({
        type: actions.SET_USER,
        payload: user,
      });

      return data.token;
    } catch (e) {
      return dispatch({
        type: actions.SIGN_IN_ERROR,
        payload: I18n.t('sign_in.validations.bad_credentials'),
      });
    }
  }, [client]);

  const resendEmailConfirmationLink = useCallback(
    email => {
      dispatch({ type: actions.RESEND_CONFIRMATION_EMAIL });

      client
        .get('/v1/users/registrations/resend_validation_email', {
          params: { email },
        })
        .catch(() => {})
        .finally(() => {
          dispatch({ type: actions.RESEND_CONFIRMATION_EMAIL_SUCCESS });

          window.location = '/resend-confirmation';
        });
    },
    [client]
  );

  const setReturnUrl = useCallback(url => {
    dispatch({
      type: actions.SET_RETURN_URL,
      payload: url,
    });
  }, []);

  const resetPassword = useCallback(
    async email => {
      dispatch({ type: actions.RESET_PASSWORD });

      client
        .post('/v1/users/auth/reset_password_token', {
          email,
        })
        .then(() => {
          dispatch({ type: actions.RESET_PASSWORD_SUCCESS });

          window.location = '/reset-password-confirmation';
        })
        .catch(
          ({
            response: {
              data: { message },
            },
          }) => {
            return dispatch({
              type: actions.RESET_PASSWORD_ERROR,
              payload: message,
            });
          }
        );
    },
    [client]
  );

  const changePassword = useCallback(
    (password, confirmPassword, passwordResetToken) => {
      dispatch({ type: actions.CHANGE_PASSWORD });

      client
        .post('/v1/users/auth/reset_password', {
          password,
          passwordConfirmation: confirmPassword,
          resetPasswordToken: passwordResetToken,
        })
        .then(() => {
          dispatch({ type: actions.CHANGE_PASSWORD_SUCCESS });

          window.location = '/change-password-confirmation';
        })
        .catch(
          ({
            response: {
              data: { message },
            },
          }) => {
            return dispatch({
              type: actions.CHANGE_PASSWORD_ERROR,
              payload: message,
            });
          }
        );
    },
    [client]
  );

  const confirmEmail = useCallback(
    confirmEmailToken => {
      dispatch({ type: actions.CONFIRM_EMAIL });

      client
        .post('/v1/users/registrations/verify_account', {
          token: confirmEmailToken,
        })
        .then(() => {
          dispatch({ type: actions.CONFIRM_EMAIL_SUCCESS });
        })
        .catch(
          ({
            response: {
              data: { message },
            },
          }) => {
            return dispatch({
              type: actions.CONFIRM_EMAIL_ERROR,
              payload: message,
            });
          }
        );
    },
    [client]
  );
  const actionMethods = {
    signIn,
    signOut,
    resendEmailConfirmationLink,
    confirmEmail,
    setReturnUrl,
    resetPassword,
    changePassword,
    refreshAuthToken,
    setToken,
  };

  return (
    <AuthContext.Provider
      value={{
        loggedIn: !!user,
        errorMessage,
        accountApproved: user && user.approved,
        token: authToken,
        user,
        currentClient: user ? user.primaryClientId : null,
        role: user ? user.role.displayName : 'Booker',
        clientServiceLevel: user ? user.clientServiceLevel : null,
        bookableServices: user ? user.clientServices.map(s => s.id) : emptyList,
        acceptedLatestConditions: user && user.acceptedLatestConditions,
        refreshingToken,
        actions: actionMethods,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthProvider;
