export const initialState = {
  loading: false,
  errorMessage: null,
  returnUrl: null,
  user: null,
  token: undefined,
  refreshingToken: false,
};

export const actions = {
  SIGN_IN: 'SIGN_IN',
  SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
  SIGN_IN_ERROR: 'SIGN_IN_ERROR',
  SIGN_OUT: 'SIGN_OUT',
  SIGN_OUT_SUCCESS: 'SIGN_OUT_SUCCESS',
  SET_TOKEN: 'SET_TOKEN',
  SET_RETURN_URL: 'SET_RETURN_URL',
  RESEND_CONFIRMATION_EMAIL: 'RESEND_CONFIRMATION_EMAIL',
  RESEND_CONFIRMATION_EMAIL_SUCCESS: 'RESEND_CONFIRMATION_EMAIL_SUCCESS',
  CONFIRM_EMAIL: 'CONFIRM_EMAILCONFIRM_EMAIL',
  CONFIRM_EMAIL_SUCCESS: 'CONFIRM_EMAIL_SUCCESS',
  CONFIRM_EMAIL_ERROR: 'CONFIRM_EMAIL_ERROR',
  RESET_PASSWORD: 'RESET_PASSWORD',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_ERROR: 'RESET_PASSWORD_SUCCESS',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_ERROR: 'CHANGE_PASSWORD_ERROR',
  REFRESH_TOKEN_SUCCESS: 'REFRESH_TOKEN_SUCCESS',
  REFRESH_TOKEN: 'REFRESH_TOKEN',
  SET_USER: 'SET_USER',
};

const reducer = (state, action) => {
  switch (action.type) {
    case actions.SIGN_IN:
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    case actions.SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    case actions.REFRESH_TOKEN:
      return {
        ...state,
        loading: true,
        refreshingToken: true,
      };
    case actions.REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        refreshingToken: false,
      };
    case actions.SIGN_IN_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: null,
      };
    case actions.SIGN_IN_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case actions.SIGN_OUT:
      return { ...state, loading: true };
    case actions.SIGN_OUT_SUCCESS:
      return {
        ...state,
        user: null,
        token: undefined,
        loading: false,
      };
    case actions.SET_RETURN_URL:
      return {
        ...state,
        returnUrl: action.payload,
      };
    case actions.RESEND_CONFIRMATION_EMAIL:
    case actions.CONFIRM_EMAIL:
    case actions.RESET_PASSWORD:
    case actions.CHANGE_PASSWORD:
      return { ...state, loading: true };
    case actions.RESEND_CONFIRMATION_EMAIL_SUCCESS:
    case actions.CONFIRM_EMAIL_SUCCESS:
    case actions.RESET_PASSWORD_SUCCESS:
    case actions.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actions.CONFIRM_EMAIL_ERROR:
    case actions.CHANGE_PASSWORD_ERROR:
    case actions.RESET_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case actions.SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
