import { useContext, useMemo } from 'react';
// eslint-disable-next-line import/no-cycle
import { AuthContext } from 'state/auth';

import clientFactory from 'utils/apiClient';

export const useApiRequest = () => {
  const { token, actions: { refreshAuthToken = () => {} } = {} } = useContext(
    AuthContext
  );

  const apiRequest = useMemo(() => {
    const client = clientFactory(token);

    client.interceptors.response.use(
      response => {
        return response;
      },
      async error => {
        const originalRequest = error.config;

        if (originalRequest.url === '/v1/users/auth/refresh_token') {
          return Promise.reject(error);
        }

        if (
          [401, 403].includes(error.response.status) &&
          !originalRequest.retry
        ) {
          originalRequest.retry = true;

          try {
            const refreshedToken = await refreshAuthToken();

            if (!refreshedToken) {
              throw new Error('refresh token missing');
            }

            client.defaults.headers.common.Authorization = `Bearer ${refreshedToken}`;

            return client(originalRequest);
          } catch (e) {
            return Promise.reject(error);
          }
        }

        return Promise.reject(error);
      }
    );

    return client;
  }, [token, refreshAuthToken]);

  return apiRequest;
};

export default useApiRequest;
